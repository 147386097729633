<template>
    <div class="info">
      <top-bar :title="isEdit?'修改房屋':'新增房屋'" :left="true"></top-bar>

      <van-popup v-model="CommunityShow"  position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.CommunityList" value-key="name" @confirm="CommunityConfirm"  @cancel="CommunityShow = false"/>
      </van-popup>

      <van-popup v-model="BuildingShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.BuildingList.length < 1 ">没有获取到楼幢信息,请先选择小区或切换小区</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.BuildingList" value-key="label"  @confirm="BuildingConfirm"  @cancel="BuildingShow = false"/>
      </van-popup>

      <van-popup v-model="unitShow"   position="bottom"  >
        <van-notice-bar color="#1989fa" background="#ecf9ff" left-icon="info-o" v-if="selectData.unitList.length < 1 ">没有获取到单元信息,请先选择楼幢或切换楼幢</van-notice-bar>
        <van-picker title="请选择" show-toolbar :columns="selectData.unitList" value-key="label"  @confirm="unitConfirm"  @cancel="unitShow = false"/>
      </van-popup>


      <van-popup v-model="attrShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.attrList" value-key="label"  @confirm="attrConfirm"  @cancel="attrShow = false"/>
      </van-popup>

      <van-popup v-model="doorModelShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.doorModelList" value-key="label"  @confirm="doorModelConfirm"  @cancel="doorModelShow = false"/>
      </van-popup>
      <van-popup v-model="manyHomeownersShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.manyHomeownersList" value-key="label"  @confirm="manyHomeownersConfirm"  @cancel="manyHomeownersShow = false"/>
      </van-popup>

      <van-popup v-model="codeResultShow"   position="bottom"  >
        <van-picker title="请选择" show-toolbar :columns="selectData.codeList" value-key="addr"
                    item-height="88px" class="codePicker" @confirm="codeConfirm"  @cancel="codeCancel"/>
      </van-popup>
      <van-popup v-model="gridShow" position="bottom">
      <grid-select
        :visible.sync="gridShow"
        :orgId="$globalData.userInfo.orgId"
        :multiple="false"
        :title="'网格/微网格'"
        :isSearch="false"
        @change="selectGrid"
      ></grid-select>
      </van-popup>

      <van-popup
          v-model="codeShow"
          position="bottom">
        <van-picker
            title="请选择"
            show-toolbar
            :columns="selectData.codeList"
            value-key="label"
            @confirm="codeConfirm"
            @cancel="codeShow = false"
        />
      </van-popup>
      <div>
        <van-cell-group>
          <van-cell>
            <template #title>
              <!-- <div class="blue-box"></div> -->
              <span class="custom-title" style="color: #333333;">{{isEdit?'修改房屋':'新增房屋'}}</span>
            </template>
          </van-cell>
          <van-cell title="所在小区" :value="selectMsg.Community" @click="CommunityShow = !isEdit" :is-link="!isEdit"
            :value-class="{'value-common':selectMsg.Community=='请选择'}"/>
          <van-cell title="所在楼幢" :value="selectMsg.Building"  @click="BuildingShow = !isEdit"  :is-link="!isEdit" required
            :value-class="{'value-common':selectMsg.Building=='请选择'}"/>
          <van-cell title="所在单元" v-if="unitVisible == true" :value="selectMsg.unit" @click="unitShow = !isEdit" :is-link="!isEdit"
            :value-class="{'value-common':selectMsg.unit=='请选择'}"/>
          <van-cell required><van-field clearable clear-trigger="always" v-model="submitData.name" label="房屋名" placeholder="请输入" input-align="right" @blur="searchCode" /></van-cell>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.areas" label="房屋面积 m²" placeholder="请输入" input-align="right"/></van-cell>
          <van-cell required><van-field clearable clear-trigger="always" v-model="submitData.floor" label="所在楼层" placeholder="请输入" input-align="right" /></van-cell>
          <van-cell title="户型" :value="selectMsg.doorModel" @click="doorModelShow = true" is-link :value-class="{'value-common':selectMsg.doorModel=='请选择'}"/>

          <van-cell
              readonly
              clickable
              name="标签管理"
              :value="selectMsg.labelStr || '请选择'"
              title="标签管理"
              :is-link="!disabled"
              @click="showlabel"
          />
          <van-popup v-model="label" position="bottom"
                      @click-overlay="labelOverlay">
            <van-cascader
                title="请选择"
                value="value"
                :field-names="{ text: 'label', value: 'value', children: 'children' }"
                :options="selectData.userCommunityLabelTree"
                active-color="#1989fa"
                @close="labelClose"
                @change="labelFinish"
            />
          </van-popup>

          <van-cell hover-class="none" v-if="labels.length>0">
            <template v-for="item in labels">
              <!--                <van-tag class="vanTag" type="primary" v-if="item.rightShow && !item.delete" :key="item.id"-->
              <!--                         :closeable="(!control.disabled) && item.rightUpdate" size="medium"-->
              <!--                         @close="removeLabel(item.value)">{{ item.name }}-->
              <!--                </van-tag>-->
              <div class="vanTag" v-if="item.rightShow && !item.delete" :key="item.id" >
                <span :style="disabled ? 'border: none' : ''">{{ item.name }}</span>
                <van-icon style="padding: 0 10px" name="cross" v-if="!disabled" @click="removeLabel(item.value)"/>
              </div>
            </template>

          </van-cell>

          <van-cell title="所属网格" :value="selectMsg.gridName" @click="!gridDisabled ? (gridShow = true) : false" :readonly="gridDisabled"
                    :value-class="{'value-common':selectMsg.gridName=='请选择'}" is-link/>
        </van-cell-group>

        <van-cell-group class="info-second">
          <van-cell>
            <template #title>
              <!-- <div class="blue-box"></div> -->
              <span class="custom-title" style="color: #333333;">出租信息</span>
              <!-- <div style="color:#387FF5;float:right" @click="infoVisible=!infoVisible"><van-icon :name="infoVisible?'arrow-up':'arrow-down'"></van-icon></div> -->
            </template>

          </van-cell>
          <div class="base-form">
            <van-form :class="{'isOld40': $isOld == 1}">
              <van-cell title="空置房自动出租" >
                <van-switch v-model="TenData.isAutoRent"  :disabled="readonly" />
              </van-cell>
              <van-cell title="开启出租" >
                <van-switch v-model="TenData.checked"  :disabled="readonly" />
              </van-cell>

              <template v-if="TenData.checked">
                <van-cell :value="TenData.safety||'请选择'"
                          input-align="right"  title="安全级别" :readonly="readonly"  @click="safety=!safety"
                          :value-class="{'value-common':TenData.safety=='请选择'}"
                          is-link>
                </van-cell>
                <van-popup v-model="safety" position="bottom">
                  <van-picker
                      title="安全级别"
                      show-toolbar
                      :columns="safetyList"
                      @confirm="onConfirmSafe"
                      @cancel="onCancelSafe"
                  />
                </van-popup>
              <van-field v-model="TenData.tel" type="number" label="手机号" :readonly="readonly"   required   placeholder="请输入手机号"/>
              <van-field name="房屋单价(元)" label="房屋单价(元)" :readonly="readonly" v-model="TenData.money" required/>
              <van-field name="房屋面积㎡" label="房屋面积㎡" :readonly="readonly" v-model="TenData.Tenantareas"
                         type="number"
                         oninput="if(value.length>6)value=value.slice(0,4)"
                         required
              />
              <van-cell-group  >
                <van-cell title="房屋设施" :border="false"> </van-cell>
                <van-cell >
                  <van-checkbox-group v-model="HasList" direction="horizontal" :disabled="readonly">
                    <van-checkbox v-for="item in  TenantList"  :key="item.value" :name="item.value" shape="square" style="padding-bottom: 5px;width:25%;margin-right: 0;">
                      <div :title="item.label">{{item.label}}</div>
                    </van-checkbox>
                  </van-checkbox-group>
                </van-cell>
              </van-cell-group >
              <van-cell title="定位"  value-class="lat" :value="TenData.placelat"  @click="getLngLat()">
                <template #right-icon>
                  <img src="@/assets/img/icon.png" alt="">
                </template>
              </van-cell>
              <van-field
                  v-model="TenData.message"
                  :readonly="readonly"
                  rows="4"
                  autosize
                  label="房屋简介"
                  type="textarea"
                  maxlength="100"
                  placeholder="请输入房屋简介"
                  show-word-limit
              />
              <van-cell class="uploadBox">
                <div class="title">上传图片</div>
                <van-col :span="24">
                  <upload-file
                      :delBtnShow="!readonly"
                      :file-list="fileList"
                      @filesUpload="filesUpload"
                      @delFile="delFile"
                      :upload-path="uploadPath"
                      :file-type="'image'"/>
                </van-col>
              </van-cell>
              </template>
            </van-form>

          </div>
        </van-cell-group>
        <van-cell-group class="info-second">
          <van-cell>
            <template #title>
              <!-- <div class="blue-box"></div> -->
              <span class="custom-title" style="color: #333333;">附属信息</span>
              <!-- <div style="color:#387FF5;float:right" @click="infoVisible=!infoVisible"><van-icon :name="infoVisible?'arrow-up':'arrow-down'"></van-icon></div> -->
            </template>
          </van-cell>
          <van-cell title="房屋属性" :value="selectMsg.useType" @click="attrShow = true" is-link :value-class="{'value-common':selectMsg.useType=='请选择'}" required/>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.houseCard" label="不动产权证号" placeholder="请输入" input-align="right"/></van-cell>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.collectiveOwnership" label="共有情况" placeholder="请输入" input-align="right"/></van-cell>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.rentalRoomNum" label="出租间数" placeholder="请输入" input-align="right"/></van-cell>
          <van-cell><van-field clearable clear-trigger="always" v-model="submitData.residentsLimit" label="限住人数" placeholder="请输入" input-align="right"/></van-cell>
          <van-cell title="是否分户" :value="selectMsg.manyHomeowners" @click="manyHomeownersShow = true" is-link :value-class="{'value-common':selectMsg.manyHomeowners=='请选择'}"/>
          <!-- <van-field clearable clear-trigger="always" v-model="submitData.code" label="统一地址编码" placeholder="请输入" input-align="right"/> -->
<!--          <van-cell title="统一地址编码" :is-link="codeResultVisible && !noResult" @click="codeResultVisible && !noResult ? codeResultShow = true : ''">-->
<!--            <template slot="default">-->
<!--              <span v-if="submitData.code && codeResultVisible">-->
<!--                <van-icon name="checked" color="#23D87B"/>{{submitData.code}}-->
<!--              </span>-->
<!--              <span v-if="!submitData.code && codeResultVisible" style="display:flex;align-items:center;justify-content:right">-->
<!--                <template v-if="noResult"><span style="margin-right:10px"><van-icon name="clear" color="#ED4747"/>匹配失败</span></template>-->
<!--                <van-button type="info" color="#ED4747" size="mini" round @click.stop="relief">发起救济</van-button>-->
<!--                <template v-if="!noResult"><span style="margin-left:10px" class="value-common">请选择</span></template>-->
<!--              </span>-->
<!--            </template>-->
<!--          </van-cell>-->
          <template v-if="isCodeShow">
            <van-cell title="标准地址名称">
              <van-field clearable type="textarea" autosize rows="1" clear-trigger="always" v-model="selectMsg.codeName" placeholder="请输入" style="text-align: right"/>
            </van-cell>
            <p v-if="isTipShow" style="line-height: 0.3rem;margin: 0.1rem 0 0 0.5rem;color: #F56C6C;font-size: 14px;">{{tipValue}}</p>
            <van-cell title="标准地址码" :value="selectMsg.codeStr || '请选择'" @click="getCode"
                      :value-class="{'value-common':selectMsg.codeStr=='请选择'}" is-link/>
          </template>
          <div class="title">备注</div>
          <van-field clearable clear-trigger="always" v-model="submitData.remark" placeholder="备注"
            type="textarea" autosize  maxlength="100" show-word-limit input-align="left" class="left"/>
          <van-row class="uploadBox">
            <div class="title">上传图片</div>
            <van-col :span="24">
              <van-uploader
                v-model="urls"
                @delete="uploaddel"
                :after-read="uploadRead"
              />
            </van-col>
          </van-row>
        </van-cell-group>

        <!-- 自定义字段 -->
        <field-view ref="fieldView" :disabled="false" :id="houseId" />
        <van-row class="btns">
          <van-col :span="24">
            <van-button type="info" size="large" @click="submit()">{{isEdit?'确认修改':'确认添加'}}</van-button>
          </van-col>
        </van-row>
      </div>
      <lng-lat v-if="lnglatVisible" ref="lnglat" @closed="closed"></lng-lat>
      <!-- <grid-select v-show="gridShow" :list="selectData.gridList" @selectLabel="selectGrid" :isArray="1" ref="gridSelect"></grid-select> -->
    </div>
</template>

<script>
import UploadFile from "@/components/upload/uploadFile.vue";
import LngLat from "./map-new.vue";
import topBar from '@/components/topBar/topBar'
import {isNumber} from '@/utils/validate'
import {getbelongSubArea, getDictTree,getVirtualDict,getMiniGrid} from '@/utils/common'
import {listComRightLabel} from "@/utils/common"
import { getImageStream } from "@/utils/index";
import fieldView from '../../components/field-view/field-view.vue';
import { mapState } from 'vuex'
// import GridSelect from './grid-select.vue'
import gridSelect from '@/components/gridSelection'
let that
export default {
  watch:{
    lnglatVisible:{
      handler(val){
        if(document.getElementById('map')&&this.lnglatVisible==false){
          console.log('关闭地图')

          document.getElementById('map').style.display="none"
        }
      }
    }
  },
  data () {
    return {
      AdminList:JSON.parse(sessionStorage.getItem('AdminList')),
      street: '',
      isEdit: false,
      houseId: '',
      urls: [],
      unitVisible: true,
      detailData: {},
      CommunityShow: false,
      BuildingShow: false,
      unitShow: false,
      attrShow: false,
      doorModelShow: false,
      manyHomeownersShow: false,
      codeResultShow: false,
      noResult: false,
      codeResultVisible: false,
      disabled: false,
      label: false,
      labels: [],
      gridShow: false,
      gridDisabled: false,
      isCodeShow: false,
      codeShow: false,
      codeMappingId: null,
      isTipShow: false,
      tipValue: '',
      selectMsg: {
        Community: '请选择',
        Building: '请选择',
        unit: '请选择',
        useType: '请选择',
        doorModel: '请选择',
        manyHomeowners: '请选择',
        description: '',
        labelStr: '请选择',
        gridName: '请选择',
        codeName: '',
        codeStr: '请选择'
      },
      submitData: {
        building:'',
        content:'',
        orgId:0,
        subarea:'',
        useType:'',
        areas: '',
        name: '',
        floor: '',
        unit:'',
        userId:'',
        doorModel: '',
        manyHomeowners: '',
        houseCard: '',
        collectiveOwnership: '',
        rentalRoomNum: '',
        residentsLimit: '',
        code: '',
        remark: '',
        imgUrl: '',
        labels: [],
        gridId: 0,
        miniGridId: 0,
        buildGrid: 0,
        buildMiniGridId: 0,
        unitGrid: 0,
      },
      selectData: {
        CommunityList: [],
        BuildingList: [],
        unitList: [],
        attrList: [],
        doorModelList: [],
        manyHomeownersList: [{value:0,label:'否'},{value:1,label:'是'}],
        userCommunityLabelTree: [],
        codeList: [],
        gridList: []
      },
      //出租
      readonly:false,
      TenantList:[],
      HasList:[],
      safety:false,
      safetyList:['A','B','C'],
      TenData:{
        safety:'',
        subareaId:0,
        Bosstel:'',
        tel:'',
        placelat:'',
        lat:'',
        lng:'',
        orgId: this.$globalData.userInfo.orgId,
        newFileMessages:[],
        fileMessages:[],
        deleteFiles:[],
        message:'',
        isAutoRent:false,
        checked:false,
        money:'',
        Tenantareas:'',

      },
      Family:false,
      userId:this.$globalData.userInfo.userId,
      hostId:'',
      lats:'',
      lngs:'',
      fileList:[],
      uploadPath:'',
      lnglatVisible:false,
    }
  },
  computed:{...mapState(['ProblemId'])},
  components :{
    topBar,
    fieldView,
    UploadFile,
    LngLat,
    // GridSelect
    gridSelect
  },
  methods: {
    init () {
      let that = this
      this.uploadPath=`files/house/${this.houseId}`
      getVirtualDict('rentHouseFacility', function (virtualDictList) {
        that.TenantList = virtualDictList
      })
      if(this.AdminList&&this.AdminList.Code=='admin') {
        console.log(this.AdminList.orgId)
            //房屋标签
      listComRightLabel(function (labels) {
          that.userCommunityLabelTree = labels
        }, this.$orgId,this.AdminList.assistId)
      }
      else{
        listComRightLabel(function (labels) {
          that.userCommunityLabelTree = labels
        })
      }
      getMiniGrid({community:this.$orgId}, function (list) {
        that.selectData.gridList = list
      })
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.houseId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.selectMsg.Community = data.buildingHouse.subareaName
          this.selectMsg.Building = data.buildingHouse.buildingName
          this.selectMsg.unit = data.buildingHouse.unitName
          this.selectMsg.useType = data.buildingHouse.useTypeStr
          this.selectMsg.doorModel = data.buildingHouse.doorModelStr
          this.selectMsg.manyHomeowners = data.buildingHouse.manyHomeowners == 0 ? '否' : data.buildingHouse.manyHomeowners == 1 ? '是' : '请选择'
          this.submitData.building = data.buildingHouse.buildingId
          this.submitData.subarea = data.buildingHouse.subarea
          this.submitData.useType = data.buildingHouse.useType
          this.submitData.areas = data.buildingHouse.areas
          this.submitData.name = data.buildingHouse.name
          this.submitData.floor = data.buildingHouse.floor
          this.submitData.unit = data.buildingHouse.unitId
          this.submitData.doorModel = data.buildingHouse.doorModel
          this.submitData.manyHomeowners = data.buildingHouse.manyHomeowners
          this.submitData.houseCard = data.buildingHouse.houseCard
          this.submitData.collectiveOwnership = data.buildingHouse.collectiveOwnership
          this.submitData.rentalRoomNum = data.buildingHouse.rentalRoomNum
          this.submitData.code = data.buildingHouse.code
          this.selectMsg.codeStr = data.buildingHouse.code
          this.selectMsg.codeName = data.buildingHouse.codeMappingEntity ? data.buildingHouse.codeMappingEntity.fullName : ''
          this.submitData.remark = data.buildingHouse.remark
          this.submitData.imgUrl = data.buildingHouse.imgUrl
          this.submitData.residentsLimit = data.buildingHouse.residentsLimit
          this.selectMsg.gridName = data.buildingHouse.gridName
          this.selectMsg.gridUser = data.buildingHouse.gridUser
          this.getBuildingInfo(data.buildingHouse.buildingId)
          this.getUnitInfo(data.buildingHouse.unitId)
          // 房屋标签
          var houseLabels = data.labels
          var labels = []
          if (houseLabels != null) {
            labels = that.formatLabel(houseLabels, true);
          }
          that.labels = labels
          let idsArray = labels.map((item) => {
            item["delete"] = false
            return item.ids
          })
          that.submitData.labels = that.submitData.labels.concat(idsArray)

          let urls = [];
          //获取图片流
          if (data.buildingHouse.imgUrl) {
            let imgUrls = data.buildingHouse.imgUrl.split(",");

            for (let i in imgUrls) {
              let u;
              let file = imgUrls[i];
              u = getImageStream(imgUrls[i]);
              let url = {
                uid: urls.length,
                status: "done", //uploading上传中，done上传完成，error上传失败
                url: u,
                fileType: "image/png",
                realUrl: file.replace(/\\/g, "/"),
              };
              urls.push(url);
            }
          }
          this.urls = urls;

          // 自定义字段
          this.$nextTick(() => {
            this.$refs.fieldView.init(this.houseId, 2, this.submitData.orgId)
          })
        } else {
            this.$refs.fieldView.init(null, 2, this.submitData.orgId)
        }
        this.$toast.clear()
      })
      this.TenantInfo()
      getVirtualDict("doorModel", function (doorModelList) {
        that.selectData.doorModelList = doorModelList
      })
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/org/subAreaList'),
        method: 'post',
        params: this.$http.adornParams({
          communityId: this.$orgId
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.CommunityList = data.subAreaList
          } else {
          this.$toast.fail(data.msg);
          }
      })
      // 获取属性列表
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/virtual/useType'),
        method: 'get'
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectData.attrList = data.useTypes
        }
      },err=> {this.$toast.console.error({message:err})})
    },
    //出租
    //租客信息
    TenantInfo(){
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/rent/info`),
        method: 'get',
        params: this.$http.adornParams({
          'id': this.houseId
        })
      }).then(({data}) => {
        if(data && data.code === 0) {
          this.TenData.safety=data.buildingHouseRent.safety||''
          if(data.buildingHouseRent.facilities) {
            this.HasList = data.buildingHouseRent.facilities.split(',') || []
          }
          else{
            this.HasList=[]
          }
          this.TenData.checked=data.buildingHouseRent.isRent==0?true:false
          this.TenData.isAutoRent=data.buildingHouseRent.isAutoRent==0?true:false
          if(data.buildingHouseRent.univalence) {
            this.TenData.money = data.buildingHouseRent.univalence == 0.0 ? '面议' : data.buildingHouseRent.univalence
          }
          else{
            this.TenData.money='面议'
          }
          this.TenData.tel=data.buildingHouseRent.mobile||this.TenData.Bosstel
          this.TenData.Tenantareas=data.buildingHouse.areas||'',
              this.TenData.lng=data.buildingHouse.codeLon||'',
              this.TenData.lat=data.buildingHouse.codeLat||''
          if(this.TenData.lng) {
            this.TenData.placelat = this.TenData.lat + ',' + this.TenData.lng
          }
          else{
            this.TenData.placelat = ''
          }
          this.TenData.message=data.buildingHouseRent.remark||''
          if (data.buildingHouseRent.url) {
            this.TenData.newFileMessages=[]
            this.fileList=[]
            data.buildingHouseRent.url.split(',').forEach((item,index)=> {
              let u;
              u = getImageStream(item)
              let url = {
                uid: this.fileList.length,
                id: index,//多图需要记录一个uid，随图片数量增加而增加
                status: 'done',//uploading上传中，done上传完成，error上传失败
                fileType: 'image',
                relativePath: item,
                url: u,
                realUrl: item.replace(/\\/g, "/")
              }
              this.fileList.push(url);
              this.TenData.newFileMessages.push(url);
              // this.TenData.fileList.push(url);
            })
          }
        }
      })

    },
    closed (latlng){
      this.lnglatVisible = false
      if(latlng.lat) {
        this.TenData.lat = latlng.lat
        this.TenData.lng = latlng.lng

        this.TenData.placelat = this.TenData.lat + ',' + this.TenData.lng
      }

    },
    filesUpload (files) {
      this.TenData.newFileMessages = files
      this.TenData.fileMessages = files
    },
    delFile (id) {
      this.TenData.deleteFiles.push(id)
    },
    getCarList() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/house/infoCarInformation'),
        method: 'post',
        params: this.$http.adornParams({
          houseId: this.houseId,
          orgId: this.$globalData.userInfo.orgId
        })
      }).then(({data}) => {
        if (data.code == 0) {
          this.carList = data.data || []
          // 加载状态结束
          this.$toast.clear()
        }
        this.$toast.clear()
      }, err => {
        this.$toast.clear()
      })
    },
    onConfirmSafe(e){
      this.TenData.safety=e
      this.safety=false
    },
    onCancelSafe(){
      this.TenData.safety=''
      this.safety=false
    },
    getLngLat () {
      if(!this.readonly) {
        this.lnglatVisible = true
        this.$nextTick(() => {
          console.log(this.TenData.orgId)
          this.$refs.lnglat.lngs=this.TenData.lng
          this.$refs.lnglat.lats=this.TenData.lat
          if(this.TenData.orgId){
            this.$refs.lnglat.init(this.TenData.orgId)
          }
          else{
            this.$refs.lnglat.init(this.AdminList.orgId)
          }

        })
      }
    },

    getGrids() {
      getMiniGrid({community:this.$orgId}, function (list) {
        if (that.submitData.buildMiniGridId) {
          that.selectData.gridList = list.filter(item => {
            return item.value == that.submitData.buildGrid
          }).map(item => {
            that.$set(item, 'disabled', true)
          })
          that.selectData.gridList[0].filter(item => {
            return item.value == that.submitData.buildMiniGridId
          })
        } else if (that.submitData.unitGrid) {
          that.selectData.gridList = list.filter(item => {
            return item.value == that.submitData.buildGrid
          }).map(item => {
            that.$set(item, 'disabled', true)
          })
          that.selectData.gridList[0].filter(item => {
            return item.value == that.submitData.unitGrid
          })
        } else if (that.submitData.buildGrid) {
          that.selectData.gridList = list.filter(item => {
            return item.value == that.submitData.buildGrid
          })
          that.$set(that.selectData.gridList[0], 'disabled', true)
        } else {
          that.selectData.gridList = []
        }
      })
    },
    getCode() {
      this.isTipShow = false
      this.tipValue = ''
      let url = '/wxapp/sys/addressCodeMapping/listByName'
      let name = true
      if (isNumber(this.selectMsg.codeName)) {
        url = '/wxapp/sys/addressCodeMapping/infoByCode'
        name = false
      }
      this.$http({
        url: this.$http.adornUrl(url),
        method: 'post',
        params: this.$http.adornParams({
          name: name ? this.selectMsg.codeName : undefined,
          code: name ? undefined : this.selectMsg.codeName,
          type: 2
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          let list = (data.list || data.sysAddressCodeMapping).map(item => {
            return {
              value: item.houseCode ? item.houseCode : item.doorCode,
              label: item.fullName,
              codeMappingId: item.id
            }
          })
          if (name) {
            this.selectData.codeList = list
          } else {
            this.selectData.codeList = list
          }
        this.codeShow = true
        } else {
          this.isTipShow = true
          this.tipValue = data.msg
          // this.$toast.fail(data.msg)
        }
      })
    },
    codeConfirm (value){
      if (value) {
        this.selectMsg.codeStr = value.value;
        this.submitData.code = value.value;
        this.selectMsg.codeName = value.label
        this.selectData.codeList.map(item => {
          if (item.value == value.value) {
            this.codeMappingId = item.codeMappingId
          }
        })
      }
      this.codeShow = false;
    },
    uploaddel(file, detail) {
      let imgUrls = this.submitData.imgUrl.split(",");
      imgUrls.splice(detail, 1);
      this.submitData.imgUrl = imgUrls.join();
    },
    // 上传图片
    uploadRead(file) {
      let that = this;
      this.$toast.loading({
        message: "上传中...",
        forbidClick: true,
        duration: 0,
        overlay: true,
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", "files/house/" + this.submitData.orgId);
        this.$http({
          url: this.$http.adornUrl("/wxapp/file/upload"),
          method: "post",
          data: formdata,
        }).then(({ data }) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            this.submitData.imgUrl = this.submitData.imgUrl
              ? this.submitData.imgUrl + "," + data.fileMessage.relativePath
              : data.fileMessage.relativePath;
            return true;
          } else {
            this.$toast.fail(data.msg);
          }
        });
      })
    },
    async submit () {
if(this.TenData.checked==true) {
  let rule = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
  const regex = /^\d+(\.\d+)?$/;
  if (this.TenData.Tenantareas < 0) {
    return this.$toast.fail('面积不能小于0')
  }
  if (this.TenData.money != '面议' && regex.test(this.TenData.money) == false) {
    return this.$toast.fail('金额只能输入数字或者面议')
  }
  if (this.TenData.money > 999999) {
    return this.$toast.fail('金额不能大于999999')
  }
  if (this.TenData.moey == '') {
    return this.$toast.fail('手机号不能为空')
  }
  if (!rule.test(this.TenData.tel)) {
    return this.$toast.fail('请输入正确手机号格式')
  }

}
      if (this.selectMsg.Community === '请选择') {return this.$toast.fail('失败:请先选择小区');}
      if (this.selectMsg.Building === '请选择') {return this.$toast.fail('失败:请先选择楼幢');}
      if (this.submitData.building !== '请选择' && this.unitVisible) {
        if ( this.selectMsg.unit === '请选择') {return this.$toast.fail('失败:请先选择单元');}
      }
      if ( this.submitData.areas === '') {return this.$toast.fail('失败:请先输入房屋面积');}
      if ( this.submitData.name === '') {return this.$toast.fail('失败:请先输入房屋名');}
      if ( this.submitData.floor === '') {return this.$toast.fail('失败:请先输入所在楼层');}
      if ( this.selectMsg.useType === '请选择') {return this.$toast.fail('失败:请先选择房屋属性');}
      if ( this.selectMsg.useType === '未知' || this.submitData.useType == 0 || this.submitData.useType == '') {
        return this.$toast.fail('失败:请正确选择房屋属性');
      }
      if (this.selectMsg.remark === '请选择') {return this.$toast.fail('失败:请先输入备注描述');}
      if (this.selectMsg.useType.indexOf('出租') > -1) {
        // if (this.submitData.rentalRoomNum == '') return this.$toast.fail('失败:请输入出租间数')
        // if (this.submitData.residentsLimit == '') return this.$toast.fail('失败:请输入限住人数')
      }

      // if (this.street == '12716' && (this.submitData.imgUrl == null || this.submitData.imgUrl === '')){
      //   return this.$toast.fail('失败:请上传房屋照片');
      // }
       // 自定义字段参数
      let extendInfoList = await this.$refs.fieldView.submitForm()

      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/house/${this.isEdit?'update':'jmgg/save'}`),
        method: 'post',
        params: this.$http.adornParams({
          loginUserId: this.$globalData.userInfo.userId,
          orgId: this.$globalData.userInfo.orgId
        }),
        data : this.$http.adornParams({
          community: this.$orgId,
          areas: this.submitData.areas,
          buildingId: this.submitData.building,
          floor: this.submitData.floor,
          id: this.isEdit ? this.houseId : 0,
          name: this.submitData.name,
          remark: this.submitData.remark,
          subarea: this.submitData.subarea + '',
          unitId: this.submitData.unit,
          useType: this.submitData.useType + '',
          doorModel: this.submitData.doorModel,
          manyHomeowners: this.submitData.manyHomeowners,
          houseCard: this.submitData.houseCard,
          collectiveOwnership: this.submitData.collectiveOwnership,
          rentalRoomNum: this.submitData.rentalRoomNum,
          residentsLimit: this.submitData.residentsLimit,
          code: this.submitData.code,
          imgUrl: this.submitData.imgUrl,
          labels: this.submitData.labels.join(','),
          gridId: this.submitData.gridId || 0,
          miniGridId: this.submitData.miniGridId ? this.submitData.miniGridId : 0,
          extendInfoList: extendInfoList
        })
      }).then(({data})=> {
          if (data.code == 0) {
            if(this.TenData.checked==true) {
              this.$http({
                url: this.$http.adornUrl('/wxapp/building/house/rent/update'),
                method: 'POST',
                data: this.$http.adornData({
                  'safety':this.TenData.safety||'',
                  'id': parseInt(this.houseId),
                  'mobile': this.TenData.tel,
                  'facility': this.HasList,
                  'isRent': this.TenData.checked == true ? 0 : 1,
                  'isAutoRent': this.TenData.isAutoRent == true ? 0 : 1,
                  'univalence': this.TenData.money == '面议' ? 0.0 : this.TenData.money,
                  'areas': this.TenData.Tenantareas,
                  'codeLon': this.TenData.lng,
                  'codeLat': this.TenData.lat,
                  'remark': this.TenData.message,
                  'fileMessage': this.TenData.newFileMessages
                })
              }).then(({data}) => {
                this.$toast.clear()
                if (data && data.code === 0) {
            this.$toast.success({
              duration: 1500,
              forbidClick: true,
              message: this.isEdit ? '修改成功' : '新增成功',
              onClose: () => {
                this.$router.go(-1)
              }
            });
                } else {
                  this.$toast.fail(data.msg)
                }
              })
            }
else{

                this.$toast.success({
                  duration: 1500,
                  forbidClick: true,
                  message: this.isEdit ? '修改成功' : '新增成功',
                  onClose: () => {
                    this.$router.go(-1)
                  }
                });
              }

          } else {
            this.$toast.fail(data.msg);
          }
      })
    },
    // 小区选择
    CommunityConfirm (value) {
      if (value) {
        this.selectMsg.Community = value.name
        this.submitData.subarea = value.id
        this.selectMsg.Building = '请选择'
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.selectData.unitList = []
        this.selectData.BuildingList = []
        this.$http({
        url: this.$http.adornUrl('/wxapp/building/info/getBySubArea'),
        method: 'post',
        params: this.$http.adornParams({
          subArea: value.id
        },false)
      }).then(({data})=> {
          if (data.code == 0) {
            this.selectData.BuildingList = data.buildings
          } else {
          this.$toast.fail(data.msg);
          }
        })
      }
      this.CommunityShow = false
    },
    // 楼栋选择
    BuildingConfirm (value) {
      if (value.divideHouse == '0') {
        return this.$toast.fail('该楼不分房，请重选选择')
      }
      if (value) {
        this.getBuildingInfo(value.value)
        this.selectMsg.Building = value.label
        this.submitData.building = value.value
        this.selectMsg.unit = '请选择'
        this.submitData.unit = ''
        this.selectData.houseList = []
        this.selectMsg.house = '请选择'
        this.submitData.house = ''
        this.selectMsg.gridName = '请选择'
        this.submitData.gridId = ''
        if (value.divideUnit == '0') {
          this.unitVisible = false
          this.getGrids()
        } else {
          this.unitVisible = true
          this.$http({
            url: this.$http.adornUrl('/wxapp/building/unit/listByBuildingId'),
            method: 'post',
            params: this.$http.adornParams({
              buildingId: value.value
            },false)
          }).then(({data})=> {
            if (data.code == 0) {
              this.selectData.unitList = data.units
            } else {
              this.$toast.fail(data.msg);
            }
          })
        }
        if (value.value && this.submitData.unit !== '') {
          this.getUnitInfo(value.value)
        }
      }
      this.BuildingShow = false
    },
    getBuildingInfo(buildingId) {
      this.$http({
        url: this.$http.adornUrl('/wxapp/building/info/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: buildingId
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.submitData.buildGrid = data.info.gridId
          this.submitData.buildMiniGridId = data.info.miniGridId
          if (this.submitData.buildMiniGridId) {
            this.gridDisabled = true
            this.selectMsg.gridName = data.info.gridName + '(' + data.info.miniGridName + ')'
          } else {
            this.gridDisabled = false
          }
        }
      })
    },
    // 单元选择
    unitConfirm (value) {
      if(value) {
      this.selectMsg.unit = value.label
      this.submitData.unit = value.value
      this.selectMsg.house = '请选择'
      this.submitData.house = ''
      this.selectMsg.gridName = '请选择'
      this.submitData.gridId = ''
        if (value.value && this.submitData.Building !== '') {
          this.getUnitInfo(value.value)
        }
      }
      this.unitShow = false
    },
    getUnitInfo (unitId) {
      this.$http({
        url: this.$http.adornUrl(`/wxapp/building/unit/info`),
        method: 'post',
        params: this.$http.adornParams({
          id: unitId
        })
      }).then(({data}) => {
        if (data && data.code === 0) {
          this.submitData.unitGrid = data.info.miniGridId
          this.submitData.gridId = data.info.gridId
          if(data.info.gridId) this.selectMsg.gridName = data.info.gridName
          this.getGrids()
          if (data.info.miniGridId) {
            this.gridDisabled = true
            this.selectMsg.gridName = data.info.gridName + '(' + data.info.miniGridName + ')'
          } else {
            this.gridDisabled = false
          }
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    // 网格选择
    selectGrid(item) {
      console.log('选择',item);
      if(item && item.length) {
        this.submitData.gridId = ''
        this.submitData.miniGridId = ''
        let checkedItem = item[0]
        this.selectMsg.gridName = checkedItem.label
        if(checkedItem.depth == 1) {
          this.submitData.gridId = checkedItem.value
        }else {
          this.submitData.miniGridId = checkedItem.value
        }
      }else {
        // this.selectMsg.gridName = '请选择'
        // this.submitData.gridId = ''
        // this.submitData.miniGridId = ''
      }
      this.gridShow = false
    },
    cancelGrid() {
      this.submitData.gridId = ''
      this.submitData.miniGridId = ''
      this.gridShow = false
    },
    // selectGrid (e) {
    //   this.submitData.gridId = 0
    //   this.submitData.miniGridId = 0
    //   const {selectList, labelCondition, depth} = e
    //   this.selectMsg.gridName = labelCondition[0].label;
    //   this.submitData.gridId = e.labelCondition[0].fatherValue || parseInt(selectList)
    //   this.submitData.miniGridId = e.labelCondition[0].fatherValue ? parseInt(selectList) : 0
    //   this.gridShow = false
    // },
    codeCancel () {
      this.codeResultShow = false
      this.submitData.code = ''
    },
    searchCode (e) {
      // this.noResult = false
      // this.codeResultVisible = true
      // let value = e.currentTarget.value
      // if (value && isNumber(value) == true) {
      //   if (value.length == 3) {
      //     this.submitData.floor = value.substring(0, 1)
      //   } else if (value.length == 4) {
      //     this.submitData.floor = value.substring(0, 2)
      //   } else {
      //     this.submitData.floor = ''
      //   }
      // } else {
      //   this.submitData.floor = ''
      // }
      // if (this.submitData.subarea && this.submitData.building && (this.unitVisible ? this.submitData.unit : true) && this.submitData.name) {
      //   this.$http({
      //     url: this.$http.adornUrl('/wxapp/spt/addressCode/addressSearch'),
      //     method: 'post',
      //     params: this.$http.adornParams({
      //       addr: this.selectMsg.Community + this.selectMsg.Building + (this.unitVisible ? this.selectMsg.unit : '') + this.submitData.name
      //     })
      //   }).then(({data})=> {
      //     if (data.code == 0) {
      //       this.selectData.codeList = data.searchAddrList
      //       this.noResult = false
      //       this.codeResultVisible = true
      //     } else if (data.code == 500) {
      //       this.noResult = true
      //       this.codeResultVisible = true
      //     } else {
      //       this.$toast.fail(data.msg);
      //     }
      //   })
      // }
    },
    // codeConfirm (value) {
    //   this.submitData.code = value.code
    //   this.codeResultShow = false
    // },
    // 属性选择
    attrConfirm (value) {
      this.selectMsg.useType = value.label
      this.submitData.useType = value.value
      this.attrShow = false
    },
    doorModelConfirm (value) {
      this.selectMsg.doorModel = value.label
      this.submitData.doorModel = value.value
      this.doorModelShow = false
    },
    manyHomeownersConfirm (value) {
      this.selectMsg.manyHomeowners = value.label
      this.submitData.manyHomeowners = value.value
      this.manyHomeownersShow = false
    },
    onCancel() {
    },
    relief () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/spt/addressRelief/addressRelief'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          buildingId: this.submitData.building,
          unitId: this.submitData.unit || '',
          houseId: ''
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.selectData.codeList = data.searchAddrList
          this.noResult = false
          this.codeResultVisible = true
        } else {
          this.$toast.fail(data.msg);
        }
      })
    },
        //
    showlabel() {
      // //debugger
      if (!this.disabled) {
        this.label = true
      }
    },
    //标签级联的关闭
    labelOverlay() {
      // this.setData({ 'control.communityLabelCascader= false })
      let temporaryLabel = that.temporaryLabel[0]
      if (!temporaryLabel) {
        return
      }
      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == temporaryLabel.value) {
          return
        }
      }

      temporaryLabel["delete"] = false

      that.labels.push(temporaryLabel)
      that.submitData.labels.push(temporaryLabel.ids)
    },
    labelClose(e) {
      this.label = false
    },
    labelFinish(e) {
      let value = []
      e.selectedOptions.map(e => value.push(e.value))
      let map = {}
      map["value"] = value
      map['options'] = e.selectedOptions
      let temporaryLabel = this.formatLabel(map, false)
      that.temporaryLabel = temporaryLabel
    },
    //删除标签
    removeLabel(value) {
      for (let i in that.submitData.labels) {
        let label = that.submitData.labels[i] + ''
        let labelArray = label.split(",")
        let id = labelArray[labelArray.length - 1]
        if (id == value) {
          that.submitData.labels.splice(i, 1)
        }
      }

      for (let i in that.labels) {
        let label = that.labels[i]
        if (label.value == value) {
          that.labels[i]["delete"] = true
        }
      }
      that.submitData.labels = that.submitData.labels
      that.labels = that.labels
      this.$forceUpdate()
    },
     /**
     * 处理标签显示效果\n
     *
     * @param resLabels
     * @param isOld 是否后端传入的旧数据
     * @returns {[]}
     */
    formatLabel: function (resLabels, isOld) {
      var labels = [];
      if (isOld) {
        for (var i = 0; i < resLabels.length; i++) {
          //是否存在第二级
          var exists2 = false;
          if (resLabels[i].parentId == 0) {
            for (var j = 0; j < resLabels.length; j++) {
              if (resLabels[j].parentId == resLabels[i].id) {
                exists2 = true;
                //是否存在第三级标签
                var exists3 = false;
                for (var k = 0; k < resLabels.length; k++) {
                  if (resLabels[k].parentId == resLabels[j].id) {
                    exists3 = true;
                    labels.push({
                      value: resLabels[k].id,
                      label: resLabels[k].name,
                      name: resLabels[i].name + "：" + resLabels[j].name + "(" + resLabels[k].name + ")",
                      ids: resLabels[i].id + "," + resLabels[j].id + "," + resLabels[k].id,
                      rightShow: resLabels[k].rightShow,
                      rightUpdate: resLabels[k].rightUpdate
                    })
                  }
                }
                if (!exists3) {
                  //不存在第三级，则加入第二级
                  labels.push({
                    value: resLabels[j].id,
                    label: resLabels[j].name,
                    name: resLabels[i].name + "：" + resLabels[j].name,
                    ids: resLabels[i].id + "," + resLabels[j].id,
                    rightShow: resLabels[j].rightShow,
                    rightUpdate: resLabels[j].rightUpdate
                  })
                }
              }
            }
            if (!exists2) {
              //不存在第二级，则加入第一级
              labels.push({
                value: resLabels[i].id,
                label: resLabels[i].name,
                name: resLabels[i].name,
                ids: resLabels[i].id,
                rightShow: resLabels[i].rightShow,
                rightUpdate: resLabels[i].rightUpdate
              })
            }
          }
        }
      } else {
        let ids = resLabels.value
        let options = resLabels.options;
        if (ids.length == 1) {
          let option = options[0]
          //只有第一级
          labels.push({
            value: option.value,
            label: option.label,
            name: option.label,
            ids: option.value,
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 2) {
          let option1 = options[0]
          let option2 = options[1]
          //不存在第三级，则加入第二级
          labels.push({
            value: option2.value,
            label: option2.label,
            name: option1.label + "：" + option2.label,
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        } else if (ids.length == 3) {
          let option1 = options[0]
          let option2 = options[1]
          let option3 = options[2]
          //不存在第三级，则加入第二级
          labels.push({
            value: option3.value,
            label: option3.label,
            name: option1.label + "：" + option2.label + "(" + option3.label + ")",
            ids: ids.join(","),
            rightShow: true,
            rightUpdate: true
          })
        }
      }
      return labels;
    },
  },
  created () {
    if(document.getElementById('map')){
      document.getElementById('map').style.display="none"
    }
      if(this.AdminList&&this.AdminList.Code=='admin') {
        this.$isOld = {}
        this.$orgId=this.AdminList.orgId

      }

    that = this
    this.street = this.$globalData.userInfo.street
    document.querySelector('html').style.backgroundColor = '#fff'
    this.submitData.orgId = this.$orgId
    this.submitData.userId = this.$globalData.userInfo.userId
    this.houseId = this.$route.query.houseId
    this.isCodeShow = this.$globalData.userInfo.codeType == 2 ? true : false
    if (this.houseId) {
      this.isEdit = true
    }
    this.init()
  },
  beforeDestroy () {
    document.querySelector('html').style.backgroundColor = ''
  }
}
</script>
<style scoped>
.title {
  padding: 24px 30px 0;
  font-size: 34px;
  font-family: 'PingFangSC-Regular';
  font-weight: 400;
  color: #666666;
}
.codePicker >>> .van-ellipsis {
  text-align: center;
  overflow: visible;
  white-space: normal;
  line-height: 60px;
}
.info >>> .van-radio-group {
  float: unset !important;
}
</style>
<style lang="scss" scoped>
.vanTag {
  margin: 0 0 5px 5px;
  border-radius: 5px;
  border: 1px solid #007AFF;
  background: rgba(0, 122, 255, 0.1);
  //padding-right: 20px;
  float: left;

  span {
    display: inline-block;
    padding: 0 17px;
    //margin-right: 20px;
    font-size: 24px;
    color: #007AFF;
    height: 56px;
    line-height: 56px;
    border-right: 1px solid #007AFF;
  }
}
.base-form {
  .house-labels {
    width: 100%;
    // height: 166px;
    background: #FFFFFF;
    padding: 0 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 26px;
    .label {
      // width: 172px;
      height: 48px;
      background: #F3F3F3;
      border-radius: 8px;
      border: 2px solid #D9D9D9;
      font-size: 26px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #666666;
      line-height: 48px;
      text-align: center;
      margin-top: 16px;
      margin-right: 24px;
      padding: 0 16px;
    }
  }
}
.mapA{
  z-index: 999;
  height: 70px;
  line-height: 70px;
  border-radius: 10px;
  color: #4581F8;
  width: 750px;
  position: absolute;
  padding: 10px;
  font-size: 34px;
  font-family: 'PingFangSC-Regular',serif;
  background-color: #F5F5F5;
}

</style>
